import React from 'react';
import { get } from 'lodash';

import {htmlToReact} from '../utils';
import CtaButtons from './CtaButtons';

export default class SectionCta extends React.Component {
    render() {
        let section = get(this.props, 'section', null);
        return (
            <section id={get(section, 'section_id', null)} className="block cta-block bg-accent outer">
              <div className="inner-large">
                <div className="grid">
                  <div className="cell block-content">
                    {get(section, 'title', null) && (
                    <h2 className="block-title">{get(section, 'title', null)}</h2>
                    )}
                    {get(section, 'subtitle', null) && (
                    <p className="block-copy">
                      {htmlToReact(get(section, 'subtitle', null))}
                    </p>
                    )}
                  </div>
                  {get(section, 'actions', null) && (
                  <div className="cell block-buttons">
                    <CtaButtons {...this.props} actions={get(section, 'actions', null)} />
                  </div>
                  )}
                </div>
              </div>
            </section>
        );
    }
}
