import React from "react";
import { Helmet } from "react-helmet";
import { get, trim } from "lodash";

import { withPrefix } from "../utils";
import "../sass/main.scss";
import Header from "./Header";
import Footer from "./Footer";
import "@fontsource/barlow-condensed/700.css";
import "@fontsource/nunito-sans";

export default class Body extends React.Component {
  render() {
    let title =
      get(this.props, "pageContext.frontmatter.title", null) +
      " | " +
      get(this.props, "pageContext.site.siteMetadata.title", null);
    let font =
      get(this.props, "pageContext.site.siteMetadata.base_font", null) ||
      "nunito-sans";
    if (get(this.props, "pageContext.frontmatter.meta_title", null)) {
      title = get(this.props, "pageContext.frontmatter.meta_title", null);
    }
    return (
      <React.Fragment>
        <Helmet>
          <title>{title}</title>
          <meta charSet="utf-8" />
          <meta
            name="viewport"
            content="width=device-width, initialScale=1.0"
          />
          <meta name="google" content="notranslate" />
          <meta
            name="description"
            content={get(
              this.props,
              "pageContext.frontmatter.meta_description",
              null
            )}
          />
          {get(this.props, "pageContext.frontmatter.canonical_url", null) ? (
            <link
              rel="canonical"
              href={get(
                this.props,
                "pageContext.frontmatter.canonical_url",
                null
              )}
            />
          ) : (
            get(this.props, "pageContext.site.siteMetadata.domain", null) &&
            (() => {
              let domain = trim(
                get(this.props, "pageContext.site.siteMetadata.domain", null),
                "/"
              );
              let page_url = withPrefix(
                get(this.props, "pageContext.url", null)
              );
              return <link rel="canonical" href={domain + page_url} />;
            })()
          )}
          {get(this.props, "pageContext.frontmatter.no_index", null) && (
            <meta name="robots" content="noindex,follow" />
          )}
          {font !== "system-sans" && (
            <link rel="preconnect" href="https://fonts.gstatic.com" />
          )}
          {font === "fira-sans" && (
            <link
              href="https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@0,400;0,600;1,400;1,600&display=swap"
              rel="stylesheet"
            />
          )}
          {get(this.props, "pageContext.site.siteMetadata.favicon", null) && (
            <link
              rel="icon"
              href={withPrefix(
                get(this.props, "pageContext.site.siteMetadata.favicon", null)
              )}
            />
          )}
          <body
            className={
              "palette-" +
              get(this.props, "pageContext.site.siteMetadata.palette", null) +
              " font-" +
              get(this.props, "pageContext.site.siteMetadata.base_font", null)
            }
          />
        </Helmet>
        <div id="page" className="site">
          <Header {...this.props} />
          <main id="content" className="site-content">
            {this.props.children}
          </main>
          <Footer {...this.props} />
        </div>
      </React.Fragment>
    );
  }
}
