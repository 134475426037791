import React from 'react';
import { get } from 'lodash';

import {Link, withPrefix, classNames} from '../utils';
import Icon from './Icon';

export default class Action extends React.Component {
    render() {
        let action = get(this.props, 'action', null);
        let action_style = get(action, 'style', null) || 'link';
        let action_icon = get(action, 'icon', null) || 'arrow-left';
        let action_icon_pos = get(action, 'icon_position', null) || 'left';
        return (
            <Link to={withPrefix(get(action, 'url', null))}
              {...(get(action, 'new_window', null) ? ({target: '_blank'}) : null)}
              {...((get(action, 'new_window', null) || get(action, 'no_follow', null)) ? ({rel: (get(action, 'new_window', null) ? ('noopener ') : '') + (get(action, 'no_follow', null) ? ('nofollow') : '')}) : null)}
              className={classNames({'button': (action_style === 'primary') || (action_style === 'secondary'), 'secondary': action_style === 'secondary', 'has-icon': get(action, 'has_icon', null)})}>
              {get(action, 'has_icon', null) && (
                <Icon {...this.props} icon={action_icon} />
              )}
              <span className={classNames({'order-first': action_icon_pos === 'right'})}>{get(action, 'label', null)}</span>
            </Link>
        );
    }
}
