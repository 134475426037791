import React from 'react';
import { get } from 'lodash';

import {Link, withPrefix} from '../utils';

export default class ActionLink extends React.Component {
    render() {
        let action = get(this.props, 'action', null);
        return (
            <Link to={withPrefix(get(action, 'url', null))}
              {...(get(action, 'new_window', null) ? ({target: '_blank'}) : null)}
              {...((get(action, 'new_window', null) || get(action, 'no_follow', null)) ? ({rel: (get(action, 'new_window', null) ? ('noopener ') : '') + (get(action, 'no_follow', null) ? ('nofollow') : '')}) : null)}>{get(action, 'label', null)}</Link>
        );
    }
}
