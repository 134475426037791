import React from 'react';
import { get } from 'lodash';

import {Link, withPrefix, markdownify} from '../utils';

export default class FooterText extends React.Component {
    render() {
        let section = get(this.props, 'section', null);
        return (
            <section className="cell widget widget-text">
              {get(section, 'image', null) && (
                get(section, 'image_url', null) ? (
                <Link className="widget-image" to={withPrefix(get(section, 'image_url', null))}><img src={withPrefix(get(section, 'image', null))} alt={get(section, 'image_alt', null)} /></Link>
                ) : 
                <p className="widget-image"><img src={withPrefix(get(section, 'image', null))} alt={get(section, 'image_alt', null)} /></p>
              )}
              {get(section, 'title', null) && (
              <h2 className="widget-title">{get(section, 'title', null)}</h2>
              )}
              {markdownify(get(section, 'content', null))}
            </section>
        );
    }
}
