import React from 'react';
import { get, map } from 'lodash';

import {htmlToReact, markdownify} from '../utils';
import FormField from './FormField';

export default class SectionContact extends React.Component {
    render() {
        let section = get(this.props, 'section', null);
        return (
            <section id={get(section, 'section_id', null)} className={'block contact-block bg-' + get(section, 'background', null) + ' outer'}>
              <div className="block-header inner-small">
                {get(section, 'title', null) && (
                <h2 className="block-title">{get(section, 'title', null)}</h2>
                )}
                {get(section, 'subtitle', null) && (
                <p className="block-subtitle">
                  {htmlToReact(get(section, 'subtitle', null))}
                </p>
                )}
              </div>
              <div className="block-content inner-medium">
                {markdownify(get(section, 'content', null))}
                <form name={get(section, 'form_id', null)} id={get(section, 'form_id', null)}{...(get(section, 'form_action', null) ? ({action: get(section, 'form_action', null)}) : null)} method="POST" data-netlify="true" data-netlify-honeypot={get(section, 'form_id', null) + '-bot-field'}>
                  <div className="screen-reader-text">
                    <label id={get(section, 'form_id', null) + '-honeypot-label'} htmlFor={get(section, 'form_id', null) + '-honeypot'}>Don't fill this out if you're human:</label>
                    <input aria-labelledby={get(section, 'form_id', null) + '-honeypot-label'} id={get(section, 'form_id', null) + '-honeypot'} name={get(section, 'form_id', null) + '-bot-field'} />
                  </div>
                  <input aria-labelledby={get(section, 'form_id', null) + '-honeypot-label'} type="hidden" name="form-name" value={get(section, 'form_id', null)} />
                  {map(get(section, 'form_fields', null), (field, field_idx) => (
                  <div key={field_idx} className="form-row">
                    <FormField {...this.props} field={field} section={section} />
                  </div>
                  ))}
                  <div className="form-row form-submit">
                    <button type="submit" className="button">{get(section, 'submit_label', null)}</button>
                  </div>
                </form>
              </div>
            </section>
        );
    }
}
