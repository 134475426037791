import React from 'react';
import { get } from 'lodash';

import CtaButtons from './CtaButtons';

export default class BlogPostFooter extends React.Component {
    render() {
        let post = get(this.props, 'page', null);
        return (
            <footer className="post-meta">
                <CtaButtons {...this.props} actions={get(post, 'frontmatter.actions', null)} />
            </footer>
        );
    }
}
