import React from "react";
import { get, map, trim } from "lodash";

import { Link, withPrefix, classNames } from "../utils";
import Action from "./Action";

export default class Header extends React.Component {
  render() {
    return (
      <header id="masthead" className="site-header outer">
        <div className="inner">
          <div className="site-header-inside">
            <div className="site-branding">
              {get(
                this.props,
                "pageContext.site.siteMetadata.header.logo_img",
                null
              ) && (
                <p className="site-logo">
                  <Link to={withPrefix("/")}>
                    <img
                      src={withPrefix(
                        get(
                          this.props,
                          "pageContext.site.siteMetadata.header.logo_img",
                          null
                        )
                      )}
                      alt={get(
                        this.props,
                        "pageContext.site.siteMetadata.header.logo_img_alt",
                        null
                      )}
                    />
                  </Link>
                </p>
              )}
              {get(this.props, "pageContext.frontmatter.template", null) ===
                "landing" ||
              get(this.props, "pageContext.frontmatter.template", null) ===
                "blog" ? (
                <h1
                  className={classNames("site-title", {
                    "screen-reader-text": get(
                      this.props,
                      "pageContext.site.siteMetadata.header.logo_img",
                      null
                    ),
                  })}
                >
                  <Link to={withPrefix("/")}>
                    {get(
                      this.props,
                      "pageContext.site.siteMetadata.title",
                      null
                    )}
                  </Link>
                </h1>
              ) : (
                <p
                  className={classNames("site-title", {
                    "screen-reader-text": get(
                      this.props,
                      "pageContext.site.siteMetadata.header.logo_img",
                      null
                    ),
                  })}
                >
                  <Link to={withPrefix("/")}>
                    {get(
                      this.props,
                      "pageContext.site.siteMetadata.title",
                      null
                    )}
                  </Link>
                </p>
              )}
            </div>
            {get(
              this.props,
              "pageContext.site.siteMetadata.header.nav_links",
              null
            ) &&
              get(
                this.props,
                "pageContext.site.siteMetadata.header.has_nav",
                null
              ) && (
                <React.Fragment>
                  <nav
                    id="main-navigation"
                    className="site-navigation"
                    aria-label="Main Navigation"
                  >
                    <div className="site-nav-inside">
                      <button id="menu-close" className="menu-toggle">
                        <span className="screen-reader-text">Open Menu</span>
                        <span className="icon-close" aria-hidden="true" />
                      </button>
                      <ul className="menu">
                        {map(
                          get(
                            this.props,
                            "pageContext.site.siteMetadata.header.nav_links",
                            null
                          ),
                          (action, action_idx) => {
                            let page_url = trim(
                              get(this.props, "pageContext.url", null),
                              "/"
                            );
                            let action_url = trim(
                              get(action, "url", null),
                              "/"
                            );
                            let action_style =
                              get(action, "style", null) || "link";
                            return (
                              <li
                                key={action_idx}
                                className={classNames("menu-item", {
                                  "current-menu-item": page_url === action_url,
                                  "menu-button": action_style !== "link",
                                })}
                              >
                                <Action {...this.props} action={action} />
                              </li>
                            );
                          }
                        )}
                      </ul>
                    </div>
                  </nav>
                  <button id="menu-open" className="menu-toggle">
                    <span className="screen-reader-text">Close Menu</span>
                    <span className="icon-menu" aria-hidden="true" />
                  </button>
                </React.Fragment>
              )}
          </div>
        </div>
      </header>
    );
  }
}
