import React from 'react';
import { get, map, orderBy } from 'lodash';

import {htmlToReact, getPages, Link, withPrefix} from '../utils';
import BlogPostFooter from './BlogPostFooter';


export default class SectionPosts extends React.Component {
    render() {
        let section = get(this.props, 'section', null);
        let display_posts = orderBy(getPages(this.props.pageContext.pages, '/blog'), 'frontmatter.date', 'desc');
        let recent_posts = display_posts.slice(0, 3);
        return (
            <section id={get(section, 'section_id', null)} className={'block posts-block bg-' + get(section, 'background', null) + ' outer'}>
              <div className="block-header inner-medium">
                {get(section, 'title', null) && (
                <div className="sub-title-wrapper">
                  <span className="sub-title-left-decor"></span>
                  <h4 className="sub-title">{get(section, 'title', null)}</h4>
                  <span className="sub-title-right-decor"></span>
                </div>
                )}
                {get(section, 'subtitle', null) && (
                <h3 className="posts-title">
                  {htmlToReact(get(section, 'subtitle', null))}
                </h3>
                )}
              </div>
              <div className="inner">
                <div className="grid post-feed">
                  {map(recent_posts, (post, post_idx) => (
                  <article key={post_idx} className="cell post">
                    <div className="card">
                      {get(post, 'frontmatter.thumb_image', null) && (
                      <Link className="post-thumbnail" to="/rentals">
                        <img src={withPrefix(get(post, 'frontmatter.thumb_image', null))} alt={get(post, 'frontmatter.thumb_image_alt', null)} />
                      </Link>
                      )}
                      <div className="post-body">
                        <header className="post-header">
                          <h3 className="post-title"><Link to="/rentals">{get(post, 'frontmatter.title', null)}</Link></h3>
                        </header>
                        <div className="post-excerpt">
                          <p>{get(post, 'frontmatter.excerpt', null)}</p>
                        </div>
                        <BlogPostFooter {...this.props} page={post} date_type={'short'} />
                      </div>
                    </div>
                  </article>
                  ))}
                </div>
              </div>
            </section>
        );
    }
}
