import React from 'react';
import { get, map } from 'lodash';

import Action from './Action';

export default class CtaButtons extends React.Component {
    render() {
        let actions = get(this.props, 'actions', null);
        return (
            map(actions, (action, action_idx) => (
              <Action key={action_idx} {...this.props} action={action} />
            ))
        );
    }
}
