import React from 'react';
import { get } from 'lodash';

import {withPrefix, markdownify} from '../utils';
import CtaButtons from './CtaButtons';

export default class SectionContent extends React.Component {
    render() {
        let section = get(this.props, 'section', null);
        return (
            <section id={get(section, 'section_id', null)} className={'block text-block bg-' + get(section, 'background', null) + ' outer'}>
              <div className="inner">
                <div className="grid">
                  {get(section, 'image', null) && (
                  <div className="cell block-preview">
                    <img src={withPrefix(get(section, 'image', null))} alt={get(section, 'image_alt', null)} />
                  </div>
                  )}
                  <div className="cell block-content">
                    {get(section, 'title', null) && (
                    <h2 className="block-title underline">{get(section, 'title', null)}</h2>
                    )}
                    <div className="block-copy">
                      {markdownify(get(section, 'content', null))}
                    </div>
                    {get(section, 'actions', null) && (
                    <div className="block-buttons">
                      <CtaButtons {...this.props} actions={get(section, 'actions', null)} />
                    </div>
                    )}
                  </div>
                </div>
              </div>
            </section>
        );
    }
}
