import React from 'react';
import { get, map } from 'lodash';

import {classNames} from '../utils';

export default class FormField extends React.Component {
    render() {
        let section = get(this.props, 'section', null);
        let field = get(this.props, 'field', null);
        return (
            <React.Fragment>
                {(get(field, 'input_type', null) !== 'checkbox') && (
                  get(field, 'label', null) && (
                  <label id={get(field, 'name', null) + '-label'} htmlFor={get(field, 'name', null)} className={classNames({'screen-reader-text': get(section, 'hide_labels', null)})}>{get(field, 'label', null)}</label>
                  )
                )}
                {(get(field, 'input_type', null) === 'checkbox') ? (
                <div className="form-checkbox">
                  <input id={get(field, 'name', null)} type="checkbox" name={get(field, 'name', null)}{...(get(field, 'label', null) ? ({"aria-labelledby": get(field, 'name', null) + '-label'}) : null)}{...(get(field, 'is_required', null) ? ({required: true}) : null)}/>
                  {get(field, 'label', null) && (
                  <label htmlFor={get(field, 'name', null)} id={get(field, 'name', null) + '-label'}>{get(field, 'label', null)}</label>
                  )}
                </div>
                ) : ((get(field, 'input_type', null) === 'select') ? (
                <div className="form-select">
                  <select id={get(field, 'name', null)} name={get(field, 'name', null)}{...(get(field, 'label', null) ? ({"aria-labelledby": get(field, 'name', null) + '-label'}) : null)}{...(get(field, 'is_required', null) ? ({required: true}) : null)}>
                    {get(field, 'default_value', null) && (
                    <option value="">{get(field, 'default_value', null)}</option>
                    )}
                    {map(get(field, 'options', null), (option, option_idx) => (
                      <option key={option_idx} value={option}>{option}</option>
                    ))}
                  </select>
                </div>
                ) : ((get(field, 'input_type', null) === 'textarea') ? (
                <textarea id={get(field, 'name', null)} name={get(field, 'name', null)} rows="5"{...(get(field, 'label', null) ? ({"aria-labelledby": get(field, 'name', null) + '-label'}) : null)}{...(get(field, 'default_value', null) ? ({placeholder: get(field, 'default_value', null)}) : null)}{...(get(field, 'is_required', null) ? ({required: true}) : null)}/>
                ) : 
                <input id={get(field, 'name', null)} type={get(field, 'input_type', null)} name={get(field, 'name', null)} {...(get(field, 'label', null) ? ({"aria-labelledby": get(field, 'name', null) + '-label'}) : null)}{...(get(field, 'default_value', null) ? ({placeholder: get(field, 'default_value', null)}) : null)}{...(get(field, 'is_required', null) ? ({required: true}) : null)} />
                ))}
            </React.Fragment>
        );
    }
}
